// src/Hocs/layout.js

import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import React from 'react';
import { Outlet } from 'react-router-dom';

const Layout = () => {
    return (
        <>
            <Header />
            <main>
                <Outlet />
            </main>
            <Footer />
        </>
    );
}

export default Layout;
