import React from 'react'
import "./Privacy.css"

const Privacy = () => {
    return (
        <div className='container-fluid foo_main'>
            <div className='container footer_maiDiv' style={{ backgroundColor: "white" }}>
                <div className='footer_divHead'>
                    <h1 className='footer_h1'>PRIVACY POLICY</h1>
                </div>
                <div className='px-2'>
                    <div className='div1_mar'>
                        <h3 className='footer_h3 mb-4'>Online Privacy Policy Scope</h3>
                        <p className='foo_p1'>Welcome! An overview of Online Privacy Policy is provided below. This Online Privacy Policy applies to your use of any online services (e.g., website, email, or mobile app) that posts a link to this Online Privacy Policy, regardless of how you access or use it. However, if you subscribe to our communications or entertainment distribution services and not this Online Privacy Policy, applies to your Subscription Services usage and account information. This Online Privacy Policy should be read in association with our Online Terms of Use, which govern the Online Service</p>
                        <p className='foo_p1'>By visiting or otherwise using the Online Service, you agree to the Online Service’s Online Terms of Use and consent to our data collection, use, and disclosure practices, and other activities as described in this Online Privacy Policy, and any additional privacy statements that may be posted on an applicable part of the Online Service. If you do not agree and consent, please discontinue use of the Online Service, and uninstall any Online Service downloads and applications.</p>
                    </div>
                    <div className='my-5'>
                        <h3 className='footer_h3 mb-4'>INFORMATION WE COLLECT.</h3>
                        <h3 className='footer_h3'>Information about You that You Provide.</h3>
                        <p className='foo_p1'>we may collect information you provide directly to us and/or its Service Providers via the Online Service. For example, we collect information when you use or register for the Online Service, subscribe to notifications, post on the Online Service, participate in promotional activities, or communicate, use or transact through the Online Service. In addition, when you interact with Third-Party Services you may be able to provide information to those third parties.</p>
                        <p className='foo_p1 my-4'>Information that we may collect may include: (1) personally identifiable information, which is information that identifies you personally, such as your first and last name, e-mail address, phone number, address, telephone number, social security number, service provider  account number, user id and password, PIN, and full payment account number (“Personally Identifiable Information” or “PII”); and (2) demographic information, such as your gender, age, zip code, interests, and recent and upcoming purchases (“Demographic Information”). Except to the extent required by applicable law, Demographic Information is “non-Personally Identifiable Information” or “non-PII” (i.e., data that is not Personally Identifiable Information under this Online Privacy Policy). In addition, PII, including, without limitation Collected Online PII</p>
                        <p className='foo_p1'>The methods that may be used on the Online Service to collect Usage Information include:</p>
                    </div>
                    <div className='my-5'>
                        <h3 className='footer_h3 mt-4'>Log Information:</h3>
                        <p className='foo_p1 mt-4'> Log information is data about your use of the Online Service, such as IP address, browser type, Internet service provider, referring/exit pages`, operating system, date/time stamps, and related data, and may be stored in log files.</p>
                    </div>
                    <div>
                        <h3 className='footer_h3'>Use of Information</h3>
                        <p className='foo_p1 mt-4'>The information we collects is used for a variety of purposes as detailed in this Online Privacy Policy. For example, your information helps us provide and improve the Online Service, communicate with you, serve advertising and offers to you, and operate our business.</p>
                        {/* <h3 className='footer_h3'>Sharing of Information</h3>
                        <p className='foo_p1'>Cox may share the information that we receive from or about you via the Online Service (or give others access to it), including your Personally Identifiable Information, for a variety of purposes, as detailed in this Online Privacy Policy.</p> */}
                    </div>
                    <div className=' mt-5'>
                        <p className='foo_p1 mt-4'><span className='footer_h3'>Information Collected by Cookies and Other Tracking Technologies:  </span>Cookies, web beacons (also known as “tracking pixels”), embedded scripts, location identifying technologies, device recognition technologies, in app tracking methods, device and activity monitoring, and other tracking technologies now and hereafter developed (“Tracking Technologies”) may be used to collect information about interactions with the Online Service or e-mails, including information about your browsing and purchasing behavior.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Privacy