import React, { useEffect, useState } from 'react'
import "./Component2.css"
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import AOS from 'aos';

const Component2 = ({ tfn, para2, getTfnFormat,imgCurve, getTfn, handleClick, img, imgS, heading1, heading2, para, list, para3 }) => {

    const [isDesktop, setIsDesktop] = useState(
        window.innerWidth > 600 ? true : false
    );
    const updateDimensions = () => {
        setIsDesktop(window.innerWidth > 700 ? true : false);
    };
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, [isDesktop]);
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);
    return (
        <div className='container-fluid mt-4' style={{ backgroundColor: "white" }}>
            <div className='container py-5 px-3'>
                <Row className='com2'>
                    <Col lg={6} className='reCom_Col2Main'>
                        <div>
                            <div className='reComp_Col2Div'>
                                <h1 className='reC_Col2h1 py-2'>{heading1}<span>{heading2}</span></h1>
                            </div>
                            <div className='divider'></div>
                            <p className='reComp_Col2p pt-4'>{para}</p>
                            <p>{para2}</p>
                            {list ?
                                <>
                                    <div className='pt-3'>
                                        <ul className='reComp_ul'>
                                            {list.map((data, index) => (
                                                <li key={index} className='reComp_li'>
                                                    <FontAwesomeIcon icon={faCheck} size='1x' color='#00a9a4' />
                                                    <span className='reComp_text'>{data}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </> : ""}
                            {para3 ? <p className='py-2'>{para3}</p> : ""}
                            <a onClick={handleClick} href={tfn === null ? "tel:+18335256234" : getTfn(tfn)} className='sec1_btn mt-4'>Contact Us</a>
                        </div>
                    </Col>
                    <Col lg={6}>
                        {img ?
                            <>
                                <div className='div_cen'>
                                    <img src={img} className='reComp_img'
                                        data-aos={isDesktop ? "slide-left" : ""}
                                    //     data-aos-duration="1500"
                                    />
                                </div>
                            </> : ""
                        }
                        {imgS ?
                            <>
                                <div className='div_cen'>
                                    <img src={imgS} className={imgCurve == "right" ? 'reComp_img2R' : 'reComp_img2L'}
                                        data-aos={isDesktop ? "slide-left" : ""}
                                    //     data-aos-duration="1500" 
                                    />
                                </div>
                            </> : ""}
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Component2