import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { customRoutes } from "./Routes/Routes";
import Layout from './Hocs/layout';
import Home from './Containers/Home/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'aos/dist/aos.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          {customRoutes.map((obj, index) => {
            return (
              // <Route
              //   key={index}
              //   exact
              //   path={obj.path}
              //   component={obj.containers}
              // />
              <Route key={index} path={obj.path} element={<obj.containers />} />
            );
          })}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
