import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logoP from "../../Assets/Images/logo_pay.png"
import "./Header.css"
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
function BasicExample() {
    const [isDesktop, setIsDesktop] = useState(
        window.innerWidth > 600 ? true : false
    );
    const updateDimensions = () => {
        setIsDesktop(window.innerWidth > 700 ? true : false);
    };
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, [isDesktop]);
    const router = useLocation().pathname
    const [tfn, setTfn] = useState(null)
    const handleClick = () => {
        // Check if the gtag function is defined
        // if (typeof window.gtag === 'function') {
        //   const phoneNumber = tfn || "1-833-525-6234"; 

        //   window.gtag('event', 'conversion', {
        //     'send_to': 'AW-606595014/9F__CNHTn94DEMbPn6EC',
        //     //  'event_callback': 'callback',
        //     'phone_number': phoneNumber,
        //   });

        // } else {
        //   console.error('Google Analytics gtag function is not defined.');
        // }

    };
    const setUrl = (url) => {
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set("tfn", url);
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + searchParams.toString();
        window.history.pushState({ path: newurl }, "", newurl);
        window.scrollTo(0, 0)
        setTfn(url)
    }

    const getTfn = (tfn) => {
        let temp = ""
        for (let i = 0; i < tfn.length; i++) {
            if (tfn[i] !== "-") {
                temp = temp + tfn[i]
            }
        }
        return "tel:+" + temp
    }

    const getTfnFormat = (tfn) => {
        let _tfn = ""
        // if(tfn.length === 14){
        tfn = tfn.split('-')
        _tfn = "(" + tfn[1] + ") " + tfn[2] + "-" + tfn[3]
        // }
        return _tfn
    }
    const urlParams = new URLSearchParams(window.location.search);
    let tfn1 = urlParams.get("tfn");

    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location.pathname);
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const handleNavLinkClick = (path) => {
        setActiveLink(path);
        if (!isDesktop) setIsNavCollapsed(true); // Close navbar on mobile after click
    };

    const handleToggleClick = () => {
        setIsNavCollapsed(!isNavCollapsed);
    };
    // const handleNavLinkClick = (path) => {
    //     setActiveLink(path);
    // };

    return (
        <>
            {isDesktop && router == "/" ?
                <div className='header_bgcolor'>
                    <div className='container header_mainDiv py-3'>
                        <div>
                            <img src={logoP} alt="Orange Raccoon Funding LLC" />
                        </div>
                        <div className='header_tagDiv'>
                            <a className='aTag' href='/'>Home</a>
                            <a className='aTag' href='/privacy-policy'>Privacy Policy</a>
                            <a className='aTag' href='/terms-conditions'>Terms & Conditions</a>
                            <a className='aTag' onClick={handleClick} href={tfn1 === null ? "tel:+18335256234" : getTfn(tfn1)}>{tfn1 === null ? getTfnFormat("1-833-525-6234") : getTfnFormat(tfn1)} </a>
                            {/* <a className='aTag' onClick={handleClick} href={tfn1 === null ? "tel:+18552003419" : getTfn(tfn1)}>{tfn1 === null ? getTfnFormat("1-855-200-3419") : getTfnFormat(tfn1)} </a>
                            <a className='aTag' onClick={handleClick} href={tfn1 === null ? "tel:+18552003191" : getTfn(tfn1)}>{tfn1 === null ? getTfnFormat("1-855-200-3191") : getTfnFormat(tfn1)} </a>
                            <a className='aTag' onClick={handleClick} href={tfn1 === null ? "tel:+18552003416" : getTfn(tfn1)}>{tfn1 === null ? getTfnFormat("1-855-200-3416") : getTfnFormat(tfn1)} </a> */}
                        </div>
                    </div>
                </div> :
                <Navbar expand="lg" className="header_nav">
                    <Container className='headerNav_con'>
                        <Navbar.Brand href="/">
                            <img src={logoP} className='logo_mobV' alt="Orange Raccoon Funding LLC" />
                        </Navbar.Brand>
                        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                        <div onClick={handleToggleClick} style={{ cursor: 'pointer' }} className='bgYel'>
                            {isNavCollapsed ? (
                                <FontAwesomeIcon icon={faBars} color='white' style={{ height: '1.1em' }} />
                            ) : (
                                <FontAwesomeIcon icon={faXmark} color='white' style={{ height: '1.1em' }} />
                            )}
                        </div>
                        <Navbar.Collapse id="basic-navbar-nav" className={`${isNavCollapsed ? '' : 'dis'}`}>
                            <Nav className="ms-auto nv_m">
                                <Nav.Link
                                    as={Link}
                                    to="/"
                                    className={`nav_borderB borderTop ${activeLink === '/' ? 'active-link' : ''}`}
                                    onClick={() => handleNavLinkClick('/')}
                                >
                                    Home
                                </Nav.Link>
                                <Nav.Link
                                    as={Link}
                                    to="/privacy-policy"
                                    className={`nav_borderB ${activeLink === '/privacy-policy' ? 'active-link' : ''}`}
                                    onClick={() => handleNavLinkClick('/privacy-policy')}
                                >
                                    Privacy Policy
                                </Nav.Link>
                                <Nav.Link
                                    as={Link}
                                    to="/terms-conditions"
                                    className={`nav_borderB ${activeLink === '/terms-conditions' ? 'active-link' : ''}`}
                                    onClick={() => handleNavLinkClick('/terms-conditions')}
                                >
                                    Terms & Conditions
                                </Nav.Link>
                                {isDesktop && (
                                    <>
                                        <Nav.Link className='phone_text'>
                                        <a className='aTag' onClick={handleClick} href={tfn1 === null ? "tel:+18335256234" : getTfn(tfn1)}>{tfn1 === null ? getTfnFormat("1-833-525-6234") : getTfnFormat(tfn1)} </a>
                                        </Nav.Link>
                                        {/* <Nav.Link className='phone_text'>
                                            +1(855) 200-3191
                                        </Nav.Link>
                                        <Nav.Link className='phone_text'>
                                            +1(855) 200-3416
                                        </Nav.Link> */}
                                    </>
                                )}
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar >
            }

        </>
    );
}

export default BasicExample;